import AnalysisCard from "../ToolsCard.vue";
import NeoLoader from "@/components/loader";
import NeoInput from "@/components/input";
import NeoButton from "@/components/button";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import OsintAxios from "@/axios/osint.js";
import NeoAnalyse from "@/components/analyse";
import neoSwitch from "@/components/toggle-switch";
import {mapGetters} from "vuex";

export default {
    name: "partial-domain-search",
    components: {
        AnalysisCard,
        NeoLoader,
        NeoInput,
        NeoButton,
        VueMultiselect,
        NeoAnalyse,
        neoSwitch,
    },
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            selectedItem: null,
            domainSearchIcon: "partialDomainSearch",
            newDomain: null,
            search: false,
            domainResults: [],
            showResult: false,
            selectVal: "",
            valueInput: "",
            isLoading: false,
            searchClicked: false,
            domainResultsCount: 0,
            domainAdded: "",
            domainAddedStatus: false,
            addClicked: false,
            request_id: "",
            searchKeyword: "",
        };
    },
    watch: {
        "$route.query.q"(to, from) {
            if (to !== from && to !== this.valueInput) {
                this.redirectToQuery();
            }
        },
    },
    computed: {
        ...mapGetters(["getUserAuthDetails", "getAllToolsData"]),
        isNeotasAdmin() {
            return this.getUserAuthDetails?.userRole?.includes("neotas-superadmin");
        },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        const dataRes = this.getAllToolsData?.find((n) => n["tool-name"] === "partial-domain-search-tool");
        if (dataRes) {
            Object.entries(dataRes).forEach(([key, value]) => {
                this[key] = value;
            });
            this.redirectToQuery(dataRes);
        }
        // this.getToolData();
    },
    beforeDestroy() {
        this.SET_ALL_TOOLS_DATA({
            ...this._data,
            "tool-name": "partial-domain-search-tool",
        });
    },
    methods: {
        getOptionsList(list) {
            return [
                {
                    group: list === "registrantUsers" ? "Registrant" : list.split("List")[0],
                    categories: this[list],
                },
            ];
        },

        handleInput() {
            if (this.selectedItem === "") {
                this.domainResults = [];
                this.showResult = false;
            }
        },

        async handleSearch() {
            // const domainRegex = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/;
            if (this.selectedItem) {
                this.addClicked = false;
                this.searchKeyword = "";
                this.newDomain = "";
                this.searchKeyword = this.selectedItem;
                let sourcesBody = {
                    search_query: this.selectedItem,
                };
                const headers = {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                    "x-refresh": true,
                };
                const res = await OsintAxios.post("/intel/partial-domain-search?search_query", sourcesBody, {headers});
                this.domainResults = res.data.data;
                this.request_id = res.data.query_id;
                if (this.domainResults) this.domainResultsCount = this.domainResults.length;
                this.showResult = true;
                this.redirectTo();
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },
        async addDomain() {
            this.selectedItem = "";
            this.showResult = false;
            if (this.newDomain) {
                const res = await OsintAxios.post(`/intel/add-domain-name?domain_name=${this.newDomain}`);
                this.domainAdded = res.data.message;
                this.domainAddedStatus = res.data.status;
                if (this.domainAdded) this.addClicked = true;
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },
        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const value = Object.values(selected_query?.query)[0];
                this.searchKeyword = value;
                this.valueInput = value;
                this.selectedItem = value;
                this.selectVal = value;
                if (!dataRes) this.handleSearch();
            } else {
                this.redirectTo();
            }
        },
    },
};
